.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
}

a.mail {
  color:#2a62ff !important;
}

a:visited, a:active, a:link, a:hover {
  color: unset;
}

.app .title {
  margin-top: 20px;
  height: 80px;
} 

.app .note {
  margin: 8px 0;
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  padding: 0 20px;
} 

.app .chart {}

.app .data {
  border: solid lightgray .5px;
  padding: 20px 48px 48px;
  margin-top: 20px;
}

.app .container {
  display: flex;
  flex-direction: row;
  margin-top: 28px;
  position: relative;
  justify-content: center;
}

.app .container .labels {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 28px;
  font-size: 42px;
  font-style: italic;
  gap: 8px;
  font-weight: bold;
}

.app .container .labels a {
  font-size: 14px;
  text-decoration: underline;
  color: #0000EE;
  margin-right: 5px;
}

.app .container .labels .yt {
  color: #ff0000;
}

.app .container .labels .bili {
  color: #01a2d6;
}

.app .container .labels .red {
  color: #ff2543;
}

.app .container .labels .douyin {
  color: #010101;
}

.app .container .labels > span:last-child {
  margin-top: 28px;
}

.app .container .values {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  font-size: 42px;
  font-weight: 400;
}

.app .container .values > span:last-child {
  margin-top: 28px;
}

.app .container .separator {
  position: absolute;
  width: 100%;
  height: .5px;
  bottom: 55px;
  background-color: lightgray;
  display: block;
  border: 0;
  padding: 0;
}

@media (max-width: 500px) {
  .app {
    zoom: .8; 
  }
  .app .data {
    border: none;
    padding: 20px 0 48px;;
  }
}
